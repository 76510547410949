/**
 * @generated SignedSource<<4989ee008f7e3b4844881bdf7b38019a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type EnrollmentType = "cancel" | "makeUp" | "single" | "%future added value";
export type NewCourseEnrollment = {
  email: string;
  name: string;
  type: EnrollmentType;
};
export type ResponsiveCourseView_SaveEnrollment_Mutation$variables = {
  courseId: string;
  date: string;
  enrollment: NewCourseEnrollment;
};
export type ResponsiveCourseView_SaveEnrollment_Mutation$data = {
  readonly enroll: boolean;
};
export type ResponsiveCourseView_SaveEnrollment_Mutation = {
  response: ResponsiveCourseView_SaveEnrollment_Mutation$data;
  variables: ResponsiveCourseView_SaveEnrollment_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "courseId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "date"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "enrollment"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "courseId",
        "variableName": "courseId"
      },
      {
        "kind": "Variable",
        "name": "date",
        "variableName": "date"
      },
      {
        "kind": "Variable",
        "name": "enrollment",
        "variableName": "enrollment"
      }
    ],
    "kind": "ScalarField",
    "name": "enroll",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ResponsiveCourseView_SaveEnrollment_Mutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ResponsiveCourseView_SaveEnrollment_Mutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "b17308d414bc9288199991615d6e218c",
    "id": null,
    "metadata": {},
    "name": "ResponsiveCourseView_SaveEnrollment_Mutation",
    "operationKind": "mutation",
    "text": "mutation ResponsiveCourseView_SaveEnrollment_Mutation(\n  $courseId: ObjectId!\n  $date: DateTime!\n  $enrollment: NewCourseEnrollment!\n) {\n  enroll(courseId: $courseId, date: $date, enrollment: $enrollment)\n}\n"
  }
};
})();

(node as any).hash = "5380549b075dac57776dd4bfe8abd6c7";

export default node;
