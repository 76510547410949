import { MessageOptions } from "../context/MessageContext"

export class ErrorService {
    static getDisplayErrorMessage = (error: Error): MessageOptions => {
        const errorCode = (error as any)?.source?.errors[0]?.extensions?.code || error.message
        switch (errorCode) {
            case "ALREADY_CANCELLED": return { message: "Du bist bereits abgemeldet", severity: "warning" }
            case "ALREADY_ENROLLED": return { message: "Du bist bereits angemeldet", severity: "warning" }
            case "LIMIT_EXCEEDED": return { message: "Der Kurs ist leider voll", severity: "error" }
            case "NO_PAST_ENROLLMENT": return { message: "Anmelden in der Vergangenheit nicht mölich", severity: "warning" }
            case "SINGLE_TIME_EXCEEDED": return { message: "Anmeldung muss bei vollen Kursen mindestens 2h voher erfolgen", severity: "warning" }
            default: return { message: "Da ist leider etwas schief gelaufen", severity: "error" }
        }
    }
}
