import React, { createContext, useState, useCallback, useEffect } from "react"
import Alert from '@mui/material/Alert'
import Snackbar from "@mui/material/Snackbar"

export type MessageOptions = {
	message: string,
	severity?: "success" | "info" | "warning" | "error",
	duration?: number
}

const MessageServiceContext = createContext<(messageOptions: MessageOptions) => void>(() => {
})

export const MessageServiceProvider = ({ children }: { children: React.ReactNode }) => {
	const [messages, setMessages] = useState<MessageOptions[]>([])
	const [currentMessage, setCurrentMessage] = useState<MessageOptions>()

	const showMessage = useCallback((options: MessageOptions) => {
		setMessages(oldMessages => [...oldMessages, options])
	}, [setMessages])

	const handleClose = useCallback(() => {
		setMessages(oldMessages => oldMessages.slice(0, -1))
	}, [])

	useEffect(() => {
		setCurrentMessage(oldMsg => messages[0] || oldMsg)
	}, [messages])

	return <React.Fragment>
		<MessageServiceContext.Provider
			value={showMessage}
			children={children}
		/>

		<Snackbar open={messages.length > 0}
			autoHideDuration={currentMessage?.duration}
			onClose={handleClose}
			anchorOrigin={{ vertical: "bottom", horizontal: "center" }}>
			<Alert onClose={handleClose}
				elevation={6}
				variant="filled"
				severity={currentMessage?.severity}>
				{currentMessage?.message}
			</Alert>
		</Snackbar>
	</React.Fragment>
}

export const useShowMessage = () => React.useContext(MessageServiceContext)
