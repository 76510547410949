import React, { useState } from 'react'

import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'

import { EnrollmentTypes } from '../EnrollmentTypes'

interface TypePickerProps {
    open: boolean
    onClose: () => void
    onSubmit: (pickedType: EnrollmentTypes) => void
}

const getTextForEnrollmentType = (type: EnrollmentTypes): string =>
    type === EnrollmentTypes.cancel ? "mich abmelden" :
        type === EnrollmentTypes.makeUp ? "Stunde nachholen" :
            type === EnrollmentTypes.single ? "Einzelstunde Buchen" : "XXXX"

export const TypePicker = ({ open, onClose, onSubmit }: TypePickerProps) => {
    const radioGroupRef = React.useRef<HTMLElement>(null);
    const [value, setValue] = useState<string>("makeUp");

    const handleSubmit = () => {
        onSubmit(EnrollmentTypes[value as keyof typeof EnrollmentTypes])
    }

    const handleEntering = () => {
        if (radioGroupRef.current != null) {
            radioGroupRef.current.focus();
        }
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue((event.target as HTMLInputElement).value);
    };
    return (
        <Dialog
            maxWidth="xs"
            open={open}
            onClose={onClose}
            TransitionProps={{
                onEntering: handleEntering
            }}>
            <DialogTitle>Ich möchte...</DialogTitle>
            <DialogContent dividers>
                <RadioGroup
                    ref={radioGroupRef}
                    aria-label="ringtone"
                    name="ringtone"
                    value={value}
                    onChange={handleChange}
                >

                    {Object.keys(EnrollmentTypes).map((option) => (
                        <FormControlLabel value={option} key={option} control={<Radio />} label={getTextForEnrollmentType(EnrollmentTypes[option as keyof typeof EnrollmentTypes])} />
                    ))}
                </RadioGroup>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={onClose} color="primary">Abbrechen</Button>
                <Button onClick={handleSubmit} color="primary">Weiter</Button>
            </DialogActions>
        </Dialog>
    );
}