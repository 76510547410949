import React from 'react'
import moment from 'moment'
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

interface CourseCardProps {
    _id: string,
    title: string,
    date: string,
    trainer: string,
    trainerImage: string,
    room: string,
    freeSlots: number,
    makeUpCount: number,
    singleCount: number,
    onClick: () => void
}


export const CourseCard = (props: CourseCardProps) => {
    return <Card sx={{
        minWidth: 150,
        backgroundColor: props.singleCount >= props.freeSlots ? "#ff717f" :
            props.makeUpCount >= props.freeSlots ? "#feff7c" : "inherit"
    }}
        onClick={props.onClick}>
        <CardHeader
            avatar={<Avatar src={props.trainerImage}>R</Avatar>}
            title={props.room}
            subheader={moment(props.date).format("HH:mm")}
        />
        <Divider />
        <CardContent>
            <Typography variant="h6" gutterBottom>{props.title}</Typography>
            <Typography variant="caption" gutterBottom>Mit {props.trainer}</Typography>
        </CardContent>
    </Card>
}