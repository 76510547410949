import React from 'react'
import moment from 'moment'

import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import { CourseCard } from './CourseCard'

type CourseDate = {
    date: string
    makeUpCount: number
    singleCount: number
}

export type Course = {
    _id: string,
    title: string
    trainer: string,
    trainerImage: string,
    room: string,
    freeSlots: number,
    dates: Readonly<CourseDate[]>
}

export interface CourseDayProps {
    forDate: Date,
    courses: Readonly<Course[]>,
    onClick: (options: {
        courseId: string,
        date: Date
    }) => void
}

const groupCourses = (forDate: Date, courses: readonly Course[]) => courses.map(course => ({
    ...course,
    dates: course.dates
        .map(date => ({ ...date, date: new Date(date.date) }))
        .filter(date => moment(forDate).startOf("day").valueOf() <= date.date.getTime() &&
            moment(forDate).endOf("day").valueOf() >= date.date.getTime())
}))
    .filter(course => course.dates.length > 0)
    .reduce<{ [key: string]: string[] }>((grouped, current) => {
        current.dates.forEach(date => {
            (grouped[date.date.getTime()] = grouped[date.date.getTime()] || []).push(current._id)
        });
        return grouped
    }, {})

export const CourseDay = ({ forDate, courses, onClick }: CourseDayProps) => {

    const relevantCourses = groupCourses(forDate, courses)

    return <Box flex={1} margin={2}>
        {Object.keys(relevantCourses).length === 0 ?
            <Typography variant="h6" align="center">Heute keine Kurse</Typography> :
            Object.keys(relevantCourses).sort().map((rcKey, index, array) => <React.Fragment key={rcKey}>
                <Grid container spacing={2} alignItems="center" wrap="nowrap">
                    {/*  <Grid item xs={2} style={{minWidth:50}}><Typography variant="body1">{moment(parseInt(rcKey)).format("HH:mm")}</Typography></Grid> */}
                    <Grid item xs>
                        <Grid container spacing={2}>
                            {relevantCourses[rcKey].map(rc => courses.find(course => course._id === rc))
                                .map((rc) => {
                                    const date = rc?.dates.find(date => new Date(date.date).getTime() === parseInt(rcKey))
                                    return rc && date && <Grid item xs key={rc._id}>
                                        <CourseCard {...rc} {...date} onClick={() => { onClick({ courseId: rc._id, date: new Date(date.date) }) }} />
                                    </Grid>
                                })}
                        </Grid>
                    </Grid>
                </Grid>
                {index !== array.length - 1 && <Divider sx={{
                    marginTop: 2,
                    marginBottom: 2
                }} />}
            </React.Fragment>)}
    </Box>
}