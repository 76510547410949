import {
    Environment,
    Network,
    RecordSource,
    RequestParameters,
    Store,
    Variables
} from 'relay-runtime';

const url = process.env.REACT_APP_GRAPHQL_URL || ""
if (!url) throw new Error('REACT_APP_GRAPHQL_URL needs to be set in env')

async function fetchQuery(
    operation: RequestParameters,
    variables: Variables,
) {
    const response = await  fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            query: operation.text,
            variables,
        }),
    })

    return await response.json()
}

const environment = new Environment({
    network: Network.create(fetchQuery),
    store: new Store(new RecordSource()),
});

export default environment;