import React from "react"
import moment from "moment"
import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"

type DayProps = {
	date: Date,
	isSelected: boolean,
	onSelect: () => void
}

const Day = (props: DayProps) => {

	const { date, onSelect, isSelected } = props
	const displayDate = moment(date)
	const isToday = displayDate.isSame(new Date(), "day")

	return <Box sx={{
		display: "flex",
		flex: "1",
		flexDirection: "column",
		marginRight: 0.5,
		"&:last-child": {
			marginRight: 0
		},
		alignItems: "stretch"
	}}>
		<Box sx={{
			height: 20,
			display: "flex",
			justifyContent: "center",
			alignItems: "center"
		}}>
			{(date.getDate() === 1 || isSelected) && <Typography sx={{ lineHeight: 1, textTransform: "none" }}
				variant="overline">{displayDate.format("MMM")}</Typography>}
		</Box>
		<Button
			sx={{
				display: "flex",
				flex: "1",
				flexDirection: "column",
				minWidth: 30,
				padding: 1,
				paddingTop: 0.75,
				paddingBottom: 0.75,
				lineHeight: 1
			}}
			onClick={onSelect}
			variant={isSelected || isToday ? "contained" : "text"}
			color={isSelected ? "primary" : "inherit"}>
			<Box>
				<Typography sx={{ lineHeight: 1 }}
					variant="body1">{displayDate.format("dd")}</Typography>
				<Typography sx={{ lineHeight: 1 }}
					variant="caption">{displayDate.format("DD")}</Typography>
			</Box>
		</Button>
	</Box>
}

export default React.memo(Day)