import React from 'react'
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import moment from 'moment'
import type { Course } from '../../../CourseDay'
import { CourseDay } from '../../../CourseDay'
import { ViewDateOptions } from '../../ViewProps'
interface DayProps {
    forDate: Date,
    courses: Readonly<Course[]>,
    onDateClicked: (options: ViewDateOptions) => void
}

const Day = ({ forDate, courses, onDateClicked }: DayProps) => {

    return <Box sx={{
        display: "flex",
        flexDirection: "column",
        paddingRight: 1
    }}>
        <Typography
            variant="h5"
            align="center">{moment(forDate).format("dd DD.MM")}</Typography>
        <CourseDay
            forDate={forDate}
            courses={courses}
            onClick={(options) => {
                const course = courses.find(course => course._id === options.courseId)
                if (!course) return

                const date = course.dates.map(d => ({ ...d, date: new Date(d.date) }))
                    .find(date => date.date.getTime() === options.date.getTime())
                if (!date) return

                onDateClicked({
                    courseId: course._id,
                    ...date,
                    freeSlots: course.freeSlots
                })
            }}
        />
    </Box>
}

export default Day