/**
 * @generated SignedSource<<7947e6b030d9d487b89570c4c4b42101>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type SingleDayQuery$variables = {
  from: string;
  to: string;
};
export type SingleDayQuery$data = {
  readonly courses: ReadonlyArray<{
    readonly _id: string;
    readonly dates: ReadonlyArray<{
      readonly date: string;
      readonly makeUpCount: number;
      readonly singleCount: number;
    }>;
    readonly freeSlots: number;
    readonly room: string;
    readonly title: string;
    readonly trainer: string;
    readonly trainerImage: string;
  }>;
};
export type SingleDayQuery = {
  response: SingleDayQuery$data;
  variables: SingleDayQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "from"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "to"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "from",
        "variableName": "from"
      },
      {
        "kind": "Variable",
        "name": "to",
        "variableName": "to"
      }
    ],
    "concreteType": "Course",
    "kind": "LinkedField",
    "name": "courses",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "_id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "title",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "trainer",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "trainerImage",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "room",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "freeSlots",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "CourseDate",
        "kind": "LinkedField",
        "name": "dates",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "date",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "makeUpCount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "singleCount",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SingleDayQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SingleDayQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "914d38fcfca388d47e1b68a3091a6c94",
    "id": null,
    "metadata": {},
    "name": "SingleDayQuery",
    "operationKind": "query",
    "text": "query SingleDayQuery(\n  $from: DateTime!\n  $to: DateTime!\n) {\n  courses(from: $from, to: $to) {\n    _id\n    title\n    trainer\n    trainerImage\n    room\n    freeSlots\n    dates {\n      date\n      makeUpCount\n      singleCount\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "151fd5e5d94fed5b73cd516dc22d0ed3";

export default node;
