import React, { useState } from 'react'

import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import TextField from '@mui/material/TextField'

export interface EnrollmentDialogSubmitOptions {
    email: string,
    name: string
}

interface EnrollmentDialogProps {
    open: boolean
    onClose: () => void
    onSubmit: (options: EnrollmentDialogSubmitOptions) => void
}


export const EnrollmentDialog = ({ open, onClose, onSubmit }: EnrollmentDialogProps) => {

    const [name, setName] = useState("")
    const [email, setEmail] = useState("")

    const emailValid = !email || !!email.match(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i)

    const handleSubmit = () => {
        onSubmit({ email, name })
    }

    return <Dialog
        maxWidth="xs"
        open={open}
        onClose={onClose}
    >
        <DialogTitle>Deine Daten</DialogTitle>
        <DialogContent dividers>
            <TextField
                autoFocus
                margin="dense"
                label="Name"
                fullWidth
                value={name}
                onChange={(e) => setName(e.target.value)}
            />
            <TextField
                error={!emailValid}
                margin="dense"
                label="Email"
                type="email"
                helperText={!emailValid && "Ungültige Email"}
                fullWidth
                value={email}
                onChange={(e) => setEmail(e.target.value)}
            />
        </DialogContent>
        <DialogActions>
            <Button autoFocus onClick={onClose} color="primary">Abbrechen</Button>
            <Button onClick={handleSubmit} color="primary" disabled={!name || !email || !emailValid}>Anmelden</Button>
        </DialogActions>
    </Dialog>
}