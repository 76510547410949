import React, { useCallback, useEffect, useState, useMemo } from "react"
import Box from '@mui/material/Box';
import moment from "moment"
import { graphql } from 'babel-plugin-relay/macro'
import { useLazyLoadQuery } from 'react-relay/hooks'

import { CourseDay } from "../../../CourseDay"
import { SingleDayQuery } from './__generated__/SingleDayQuery.graphql'
import { ViewProps } from "../../ViewProps"

import { Virtual } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react'

import 'swiper/css';
import 'swiper/css/virtual';
interface SingleDayProps extends ViewProps {
	today: Date,
	selectedDate: Date
	setSelectedDate: (newDate: Date) => void
}

const query = graphql`
	query SingleDayQuery($from:DateTime!, $to:DateTime!) {
		courses(from:$from, to:$to){
			_id,
			title
			trainer,
			trainerImage,
			room,
			freeSlots,
			dates{
				date
				makeUpCount
				singleCount
			}
		}
	}
`
const slideCount = 1000

const SingleDayComp = ({ today, onDateClick, selectedDate, setSelectedDate }: SingleDayProps) => {

	const [swiper, setSwiper] = useState<any>(undefined);

	const calcDateForIndex = useCallback((index: number) => moment(today).add(index, "days").toDate(), [today])

	const index = useMemo(() => moment(today).diff(moment(selectedDate), "days", false), [today, selectedDate])

	const setIndex = useCallback((index: number) => {

		setSelectedDate(calcDateForIndex(index - slideCount / 2))
	}, [calcDateForIndex, setSelectedDate])

	const showFrom = moment(selectedDate).startOf("day").toString()
	const showTo = moment(selectedDate).endOf("day").toString()

	const data = useLazyLoadQuery<SingleDayQuery>(query, { from: showFrom, to: showTo })

	useEffect(() => {
		swiper?.slideTo((slideCount / 2) - index, 300, false)
	}, [swiper, index])


	return (
		<Box flex={1} display="flex">
			<Swiper onSwiper={setSwiper} modules={[Virtual]} virtual style={{ flex: 1 }} onSlideChange={(swiper) => setIndex(swiper.activeIndex)}>
				{Array.from({ length: slideCount }).map(
					(el, idx) => {
						const index = idx - slideCount / 2

						return <SwiperSlide key={`week${index}`} virtualIndex={index}>
							<CourseDay
								forDate={calcDateForIndex(index)}
								courses={data.courses}
								onClick={(options) => {
									const course = data.courses.find(course => course._id === options.courseId)
									if (!course) return

									const date = course.dates.map(d => ({ ...d, date: new Date(d.date) }))
										.find(date => date.date.getTime() === options.date.getTime())
									if (!date) return

									onDateClick({
										courseId: course._id,
										...date,
										freeSlots: course.freeSlots
									})
								}}
							/>
						</SwiperSlide>
					}
				)}
			</Swiper >
		</Box>
	)
}

export const SingleDay = React.memo(SingleDayComp)