import * as React from 'react';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers/PickersDay';
import moment from 'moment';


interface WeekPickerProps {
  onDateSelected: (date: Date | undefined) => void,
  selectedDate: Date | undefined
}
interface CustomPickerDayProps extends PickersDayProps<moment.Moment> {
  dayIsBetween: boolean;
  isFirstDay: boolean;
  isLastDay: boolean;
}

const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) =>
    prop !== 'dayIsBetween' && prop !== 'isFirstDay' && prop !== 'isLastDay',
})<CustomPickerDayProps>(({ theme, dayIsBetween, isFirstDay, isLastDay }) => ({
  ...(dayIsBetween && {
    borderRadius: 0,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.primary.dark,
    },
  }),
  ...(isFirstDay && {
    borderTopLeftRadius: '50%',
    borderBottomLeftRadius: '50%',
  }),
  ...(isLastDay && {
    borderTopRightRadius: '50%',
    borderBottomRightRadius: '50%',
  }),
})) as React.ComponentType<CustomPickerDayProps>;

export const WeekPicker = ({onDateSelected, selectedDate}: WeekPickerProps) => {

  const renderWeekPickerDay = (
    date: moment.Moment,
    selectedDates: Array<moment.Moment | null>,
    pickersDayProps: PickersDayProps<moment.Moment>,
  ) => {
    if (!date) {
      return <PickersDay {...pickersDayProps} />;
    }

    const start = moment(selectedDate).startOf('week');
    const end = moment(selectedDate).endOf('week');

    const dayIsBetween = date.isBetween(start, end, null, '[]');
    const isFirstDay = date.isSame(start, 'day');
    const isLastDay = date.isSame(end, 'day');

    return (
      <CustomPickersDay
        {...pickersDayProps}
        disableMargin
        dayIsBetween={dayIsBetween}
        isFirstDay={isFirstDay}
        isLastDay={isLastDay}
      />
    );
  };

  return (
    <DesktopDatePicker
      value={selectedDate}
      onChange={(newValue) => {
        onDateSelected(newValue?.toDate());
      }}
      renderDay={renderWeekPickerDay}
      renderInput={(params) => <TextField {...params} disabled={true} />}
      inputFormat="MMM YYYY, w[. Woche]"
      disableMaskedInput={true}
    />
  );
}

