import React from 'react'

import MuiAppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
/* import IconButton from '@mui/material/IconButton' */
import Typography from '@mui/material/Typography'

export const AppBar = () => {
    return <MuiAppBar position="static" sx={{ marginBottom: 2 }}>
        <Toolbar>
            <Typography variant="h6" sx={{ flexGrow: 1 }}>Nachholsystem</Typography>
        </Toolbar>
    </MuiAppBar>
}