import React from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import Box from '@mui/material/Box';

import { AppBar } from './AppBar'
import { CourseView } from '../../CourseView'

export const Navigation = () =>
    <Box sx={{
        display: "flex",
        height: "100vh",
        width: "100vw",
        maxWidth: "100%",
        flexDirection: "column"
    }} >
        <AppBar />
        <Routes>
            <Route path="/" element={<CourseView />} />
            <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
    </Box>