import React, { useState, useEffect } from 'react'
import { EnrollmentTypes } from "../EnrollmentTypes";
import { TypePicker } from './TypePicker';
import { EnrollmentDialog, EnrollmentDialogSubmitOptions } from './EnrollmentDialog';

export interface Enrollment {
    type: EnrollmentTypes,
    name: string,
    email: string
}

interface WizardProps {
    open: boolean
    onClose: () => void
    onTypeSelected: (pickedType: EnrollmentTypes) => void
    onSubmit: (enrollment: Enrollment) => void
}


export const Wizard = ({ open, onClose, onSubmit, onTypeSelected }: WizardProps) => {
    const [step, setStep] = useState(-1)
    const [type, setType] = useState<EnrollmentTypes>(EnrollmentTypes.makeUp)

    useEffect(() => {
        setStep(open ? 0 : -1)
    }, [open])

    const handleModeSubmit = (pickedType: EnrollmentTypes) => {
        setType(pickedType)
        onTypeSelected(pickedType)
        setStep(step => step + 1)
    }

    const handleFormSubmit = ({ email, name }: EnrollmentDialogSubmitOptions) => {
        onSubmit({
            type,
            email,
            name
        })
        setStep(-1)
    }

    return <React.Fragment>
        <TypePicker onClose={onClose}
            open={step === 0}
            onSubmit={handleModeSubmit} />
        <EnrollmentDialog open={step === 1}
            onClose={onClose}
            onSubmit={handleFormSubmit}
        />
    </React.Fragment>
}