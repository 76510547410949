import React from 'react';
import { RelayEnvironmentProvider } from 'react-relay/hooks'
import { BrowserRouter as Router } from 'react-router-dom'

import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import moment from 'moment'
import "moment/locale/de-at";

import environment from '../../relayEnvironment'
import { Navigation } from '../Navigation'
import theme from '../../theme'
import { MessageServiceProvider } from '../../context';
import ErrorBoundary from '../../ErrorBoundary';

moment.locale("de-at")

export const App = () => {
  return (
    <ErrorBoundary>
      <RelayEnvironmentProvider environment={environment}>
        <CssBaseline />
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <MessageServiceProvider>
                <Router>
                  <Navigation />
                </Router>
              </MessageServiceProvider>
            </LocalizationProvider>
          </ThemeProvider>
        </StyledEngineProvider>
      </RelayEnvironmentProvider>
    </ErrorBoundary>
  );
}

export default App;
