import React, { useState } from "react"
import { useTheme } from "@mui/material/styles"
import useMediaQuery from "@mui/material/useMediaQuery"
import { graphql } from 'babel-plugin-relay/macro'
import { useMutation } from 'react-relay/hooks'

import { SingleDayView } from "./SingleDayView"
import { EnrollmentDialog, Enrollment } from "../../EnrollmentDialog"
import type { ViewDateOptions } from '../ViewProps'

import { ResponsiveCourseView_SaveEnrollment_Mutation, EnrollmentType } from './__generated__/ResponsiveCourseView_SaveEnrollment_Mutation.graphql'
import { EnrollmentTypes } from "../../EnrollmentDialog/EnrollmentTypes"
import { useShowMessage } from "../../../context"
import WeekView from "./WeekView"
import { ErrorService } from "../../../services/ErrorService"

const enrollMutation = graphql`
    mutation ResponsiveCourseView_SaveEnrollment_Mutation($courseId: ObjectId!, $date: DateTime!, $enrollment: NewCourseEnrollment!){
        enroll(courseId:$courseId, 
			date:$date
			enrollment:$enrollment
		)
    }
`

const ResponsiveCourseView = () => {
	const theme = useTheme()
	const showMessage = useShowMessage()
	const isLarge = useMediaQuery(theme.breakpoints.up("lg"))
	const [saveEnrollment] = useMutation<ResponsiveCourseView_SaveEnrollment_Mutation>(enrollMutation)

	const [openDate, setOpenDate] = useState<ViewDateOptions>()

	const handleEnrollmentDialogClose = () => setOpenDate(undefined)
	const handleEnrollmentDialogSubmit = (enrollment: Enrollment) => {
		if (!openDate) return
		saveEnrollment({
			variables: {
				courseId: openDate.courseId,
				date: openDate.date.toISOString(),
				enrollment: {
					email: enrollment.email,
					name: enrollment.name,
					type: enrollment.type as unknown as EnrollmentType
				}
			},
			onCompleted: () => {
				setOpenDate(undefined)
				showMessage({ message: `Danke für deine ${enrollment.type === EnrollmentTypes.cancel ? "Abmeldung" : "Anmeldung"}, du bekommst noch eine Mail zur bestätigung`, severity: "success" })
			},
			onError: (e) => {
				setOpenDate(undefined)
				showMessage(ErrorService.getDisplayErrorMessage(e))
			}
		})
	}

	const handleTypeSelected = (type: EnrollmentTypes) => {
		if (openDate && ((type === EnrollmentTypes.makeUp && openDate.freeSlots <= openDate.makeUpCount) || (type === EnrollmentTypes.single && openDate && openDate.freeSlots <= openDate.singleCount))) {
			setOpenDate(undefined)
			showMessage({
				message: "Dieser Kurs ist leider ausgebucht",
				severity: "error"
			})
		}

		if (openDate && (openDate.date.getTime() - new Date().getTime()) / 36e5 <= 2 && type === EnrollmentTypes.single && openDate.freeSlots <= openDate.makeUpCount) {
			setOpenDate(undefined)
			showMessage({ message: "Anmeldung muss bei vollen Kursen mindestens 2h voher erfolgen", severity: "warning" })
		}
	}

	const handleDateClick = (options: ViewDateOptions) => {

		if ((options.date.getTime() - new Date().getTime()) / 36e5 <= 0)
			showMessage({
				message: "Anmeldungen in der Vergangenheit sind nicht möglich",
				severity: "warning"
			})
		else
			setOpenDate(options)
	}

	return <React.Fragment>
		<EnrollmentDialog
			open={!!openDate}
			onClose={handleEnrollmentDialogClose}
			onSubmit={handleEnrollmentDialogSubmit}
			onTypeSelected={handleTypeSelected} />
		{isLarge ?
			<WeekView onDateClick={handleDateClick} /> :
			<SingleDayView onDateClick={handleDateClick} />
		}

	</React.Fragment>
}

export default ResponsiveCourseView