import React from "react"
import Box from "@mui/material/Box"
import moment from "moment"
import Day from "./Day"

type WeekProps = {
	weekNr: number,
	year: number,
	selectedDay: Date,
	onDaySelected: (day: Date) => void
}

const Week = ({ weekNr, selectedDay, onDaySelected, year }: WeekProps) => {
	const refDate = moment().weekYear(year).week(weekNr)
	const days = Array.from({ length: 7 }, (v, k) => k)

	return <Box display="flex" justifyContent="space-around"
		alignItems="flex-end" style={{ paddingBottom: 3 }}>
		{days.map((day) => {
			const dayMoment = refDate.weekday(day).startOf("day")
			const date = dayMoment.toDate()
			return <Day key={day} date={dayMoment.toDate()}
				isSelected={dayMoment.isSame(selectedDay, "day")}
				onSelect={() => onDaySelected(date)}
			/>
		}
		)}
	</Box>
}

export default React.memo(Week)