import React from 'react'
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography'

export const LoadingIndicator = () => {
    return <Box sx={{ display: 'flex', flex: 1, flexDirection: "column", alignItems: "center", padding: 3 }}>
        <CircularProgress />
        <Typography sx={{ marginTop: 0.5 }} variant='caption'>Ladet...</Typography>
    </Box>
}