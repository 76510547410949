import React, { Suspense, useState } from "react"
import Box from "@mui/material/Box"
import { WeekPicker } from "../../../WeekPicker"
import { ViewProps } from "../../ViewProps"
import WeekNavigator from "./WeekNavigator";
import { LoadingIndicator } from "../../../LoadingIndicator";


const WeekView = (props: ViewProps) => {
	const { onDateClick } = props

	const [referenceDate, setReferenceDate] = useState<Date>(new Date())

	const setRefDateNullable = (date: Date | undefined) => {
		setReferenceDate(date || new Date())
	}

	return (
		<Box sx={{
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
			flex: 1,
			maxWidth: "100%",
			padding: 1
		}}>
			<WeekPicker onDateSelected={setRefDateNullable}
				selectedDate={referenceDate} />
			<Suspense fallback={<LoadingIndicator />}>
				<WeekNavigator onDateClick={onDateClick} referenceDate={referenceDate} setReferenceDate={setReferenceDate} />
			</Suspense>
		</Box>
	);
}

export default React.memo(WeekView)