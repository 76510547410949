import React from "react"
import Box from "@mui/material/Box"
import IconButton from "@mui/material/IconButton"
import { graphql } from 'babel-plugin-relay/macro'
import { useLazyLoadQuery } from 'react-relay/hooks'
import moment from "moment"
import { ViewProps } from "../../ViewProps"

import Day from './Day'
import { WeekNavigatorQuery } from './__generated__/WeekNavigatorQuery.graphql'

interface WeekNavigatorProps extends ViewProps {
    referenceDate: Date,
    setReferenceDate: (newDate: Date) => void
}

const query = graphql`
	query WeekNavigatorQuery($from:DateTime!, $to:DateTime!) {
		courses(from:$from, to:$to){
			_id,
			title
			trainer,
			trainerImage,
			room,
			freeSlots,
			dates{
				date
				makeUpCount
				singleCount
			}
		}
	}
`

const getDaysBetween = (from: Date, to: Date): Date[] => {
    const start = new Date(from.getFullYear(), from.getMonth(), from.getDate())
    const days: Date[] = []
    do {
        days.push(new Date(start))
        start.setDate(start.getDate() + 1)
    } while (start < to)
    return days
}

const WeekNavigator = ({ referenceDate, setReferenceDate, onDateClick }: WeekNavigatorProps) => {

    const showFrom = moment(referenceDate).startOf("weeks").toDate()
    const showTo = moment(referenceDate).endOf("weeks").toDate()

    const data = useLazyLoadQuery<WeekNavigatorQuery>(query, { from: showFrom.toISOString(), to: showTo.toISOString() })

    const nextWeek = () => setReferenceDate(new Date(referenceDate.setDate(referenceDate.getDate() + 7)))
    const lastWeek = () => setReferenceDate(new Date(referenceDate.setDate(referenceDate.getDate() - 7)))

    return (
        <Box sx={{
            flex: 1,
            paddingTop: 3,
            maxHeight: "40em",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
        }}>
            <IconButton aria-label="Letzte Woche" onClick={lastWeek} size="large">
                <span className="material-icons">chevron_left</span>
            </IconButton>
            <Box sx={{
                display: "flex",
                justifyContent: "center",
                marginBottom: 1
            }}>{
                    getDaysBetween(showFrom, showTo).map((day, index) =>
                        <Day key={day.toISOString()}
                            forDate={day}
                            courses={data.courses}
                            onDateClicked={onDateClick}
                        />)
                }</Box>
            <IconButton aria-label="Nächste Woche" onClick={nextWeek} size="large">
                <span className="material-icons">chevron_right</span>
            </IconButton>
        </Box>
    );
}

export default React.memo(WeekNavigator)