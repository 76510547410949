import { createTheme } from '@mui/material/styles';

export default createTheme({
    palette: {
        primary: {
            main: "#0cf232",
            light: "#85f898",
            dark: "#067919",
            contrastText: "#fff"
        },
        text: {
            primary: "#000"
        }
    },
})