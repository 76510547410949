import React, { useEffect, useMemo, useState } from "react"
import moment from "moment"
import Box from "@mui/material/Box"
import IconButton from "@mui/material/IconButton"
import Week from "./Week"

import { Virtual } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react'

import 'swiper/css';
import 'swiper/css/virtual';

type DaySelectorProps = {
	selectedDay: Date,
	today: Date,
	onDaySelected: (day: Date) => void
}

const slideCount = 1000

const DaySelector = (props: DaySelectorProps) => {
	const { selectedDay, onDaySelected, today } = props

	const [swiper, setSwiper] = useState<any>(undefined);

	const getMomentForIndex = (index: number) => moment(today).add(index, "weeks")

	const getIndexForDate = useMemo(() => (date: Date) => {
		const weekDiff = moment(date).week() - moment(today).week()

		// week diff is fucked when selected year != target year
		// to compensate we take the year diff into account
		const yearDiff = moment(date).weekYear() - moment(today).weekYear()

		return weekDiff + (yearDiff * 52)
	}, [today])


	useEffect(() => {
		swiper?.slideTo(getIndexForDate(selectedDay) + (slideCount / 2), 100, false)
	}, [selectedDay, getIndexForDate, swiper])

	const prev = () => {
		swiper?.slidePrev()
	}
	const next = () => {
		swiper?.slideNext()
	}

	return (
		<Box sx={{
			display: "flex",
			width: "100%",
			minWidth: 0,
			flexDirection: "row",
			minHeight: 42,
			"&:first-of-type": {
				paddingRight: 0.5
			},
			"&:last-of-type": {
				paddingLeft: 0.5
			}
		}}>
			<IconButton
				sx={{ padding: 0 }}
				onClick={prev}
				size="large">
				<span className="material-icons">chevron_left</span>
			</IconButton>
			<Box sx={{ flex: 1, minWidth: 0 }}>
				<Swiper onSwiper={setSwiper} modules={[Virtual]} virtual  >
					{Array.from({ length: slideCount }).map(
						(el, idx) => {
							const index = idx - slideCount / 2

							return <SwiperSlide key={`week${index}`} virtualIndex={index}>
								<Week weekNr={getMomentForIndex(index).week()}
									year={getMomentForIndex(index).get("year")}
									selectedDay={selectedDay}
									onDaySelected={onDaySelected} />
							</SwiperSlide>
						}
					)}
				</Swiper>
			</Box>
			<IconButton
				sx={{ padding: 0 }}
				onClick={next}
				size="large">
				<span className="material-icons">chevron_right</span>
			</IconButton>
		</Box >
	);
}

export default React.memo(DaySelector)